.stat-block {
  background-image: linear-gradient(#E6D29D, #f8f3d8 10%);
  border-color: #A3884E;
  // background-color: #f8f3d8;
  label {
    color: #822000;
    font-weight:bold;
    margin-right:0.5em;
  }
  .trait-block, .action-block, .reaction-block {
    label {
      color: black;
    }
  }
  h3, h4 {
    color: #822000;
    font-variant: small-caps;
  }
  section {
    padding:1rem 0;
    border-bottom: 4px solid #DBAFA3;
    border-image: linear-gradient(90deg, #822000, #f8f3d8) 1;
  }
  section:first-child {
    padding-top:0;
  }
  section:last-child {
    padding-bottom:0;
    border-bottom: 0;
  }
  table {
    width: 100%
  }
  td, th {
    text-align:center;
  }
  th {
    color: #822000;
  }
  .form-control {
    display:inline;
    width:auto;
  }
  input[type='integer'] {
    width: 3em;
  }
  textarea.form-control {
    display:block;
    width:100%;
  }
}
